import type { StoryblokContent } from '../storyblok'
import type { EdgeStyles } from './shared'

export interface NewsletterBlock extends StoryblokContent {
  component: 'newsletter-block'
  edge_style: EdgeStyles
}

export function normalizeNewsletterBlock(blok: NewsletterBlock) {
  return {
    edge_style: blok.edge_style,
  }
}
