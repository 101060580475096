<script setup lang="ts">
import type { NewsletterBlock } from '~/types/storyblok/newsletter-block'
import { normalizeNewsletterBlock } from '~/types/storyblok/newsletter-block'
import { getEdgeClasses } from '~/lib/getEdgeClasses'

interface Props {
  blok: NewsletterBlock
}

const props = defineProps<Props>()
const blok = toRef(props, 'blok')

const storyblokStore = useStoryblokStore()
const { state: storyblok } = storeToRefs(storyblokStore)

const newsletterBlock = computed(() => normalizeNewsletterBlock(blok.value))
</script>

<template>
  <div
    v-editable="blok"
    class="newsletter-block"
    :class="[getEdgeClasses(newsletterBlock.edge_style)]"
  >
    <Newsletter
      v-if="storyblok.newsletter"
      :newsletter="storyblok.newsletter"
      class="newsletter-content"
      context="newsletter-block"
      background-color="var(--blue)"
      text-color-primary="var(--white)"
      text-color-secondary="var(--text-contrast-secondary)"
      wave-color="White"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/edges';
.newsletter-block {
  background-color: var(--blue);
  align-items: center;
}

.newsletter-content {
  margin: 0 auto;
  max-width: 545px;
  padding: 7.2rem 2.4rem;
}

.teeth {
  @include teeth;
  margin-top: -1.2rem;
}

.scallop {
  @include scallop;
  margin-top: -1.2rem;
}

.wave {
  @include wave;
  margin-top: -1.2rem;
}
</style>
